/**
 * Created by sametkuru on 7.03.2017.
 */
(function ($) {
    'use strict';
    $.ajaxSetup({
        headers: {
            'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
        }
    });
    /*******************************
     -----------serialize override------------
     *******************************/
    $.fn.serializeControls = function(options) {
        var data = {};

        function buildInputObject(arr, val) {
            if (arr.length < 1)
                return val;
            var objkey = arr[0];
            if (objkey.slice(-1) == "]") {
                objkey = objkey.slice(0,-1);
            }
            var result = {};
            if (arr.length == 1) {
                result[objkey] = val;
            } else {
                arr.shift();
                var nestedVal = buildInputObject(arr,val);
                result[objkey] = nestedVal;
            }
            return result;
        }

        $.each(this.serializeArray(options), function() {
            var val = this.value;
            var c = this.name.split("[");
            var a = buildInputObject(c, val);
            $.extend(true, data, a);
        });

        return data;
    };
    $.fn.serialize = function (options) {
        return $.param(this.serializeArray(options));
    };
    $.fn.serializeArray = function (options) {
        var o = $.extend({
            checkboxesAsBools: false,
            dataAttr: false
        }, options || {});
        var rselectTextarea = /select|textarea/i;
        var rinput = /text|hidden|password|search/i;
        var data = {};
        data = this.map(function () {
            return this.elements ? $.makeArray(this.elements) : this;
        }).filter(function () {
                return this.name && !this.disabled &&
                    (this.checked
                    || (o.checkboxesAsBools && this.type === 'checkbox')
                    || rselectTextarea.test(this.nodeName)
                    || rinput.test(this.type));
        }).map(function (i, elem) {
                var val = $(this).val();
                return val == null ?
                    null :
                    $.isArray(val) ?
                        $.map(val, function (val, i) {
                            return {name: elem.name, value: val};
                        }) :
                        {
                            name: elem.name,
                            value: (this.type === 'checkbox') ? //moar ternaries!
                                (this.checked ? val : '0') :
                                val
                        };
            }).get();
        if (o.dataAttr == true) {
            $.each($(this).data(), function (key, val) {
                if (key == 'ek_form') {
                    if (val != "") {
                        var ek_form = $('#' + val).serializeArray();
                        $.each(ek_form, function (index, val) {
                            data.push({name: val.name, value: val.value});
                        });
                    }
                }
                else {
                    data.push({name: key, value: val});
                }
            });
        }
        return data;
    };
    $.fn.reset = function () {
        $(this).find('label.error').remove();
        $(this).find('.error').removeClass('error');

        return this.each(function ()
        {
            if (!$(this).attr('data-clear-change') && $(this).attr('data-clear-change') != 'false')
            {
                var type = this.type, tag = this.tagName.toLowerCase();
                if (tag == 'form')
                    return $(':input:not(:disabled)', this).reset();
                if (type == 'text' || type == 'password' || tag == 'textarea')
                {
                    this.value = '';
                }
                else if (type == 'checkbox' || type == 'radio')
                {
                    this.checked = false;
                    $(this).iCheck('update');
                }
                else if (tag == 'select')
                {
                    $(this).val(null);
                    $(this).trigger('change');
                }
            }
        });
    };
    //js de türkçe karakter problemi için eklendi
    String.prototype.turkishToUpper = function(){
        var string = this;
        var letters = { "i": "İ", "ş": "Ş", "ğ": "Ğ", "ü": "Ü", "ö": "Ö", "ç": "Ç", "ı": "I" };
        string = string.replace(/(([iışğüçö]))/g, function(letter){ return letters[letter]; })
        return string.toUpperCase();
    };

    String.prototype.turkishToLower = function(){
        var string = this;
        var letters = { "İ": "i", "I": "ı", "Ş": "ş", "Ğ": "ğ", "Ü": "ü", "Ö": "ö", "Ç": "ç" };
        string = string.replace(/(([İIŞĞÜÇÖ]))/g, function(letter){ return letters[letter]; })
        return string.toLowerCase();
    };

// Case insensitive contains search
    $.expr.pseudos.icontains = function (obj, index, meta) {
        var $obj = $(obj);
        var haystack = ($obj.data('tokens') || $obj.text()).toString().turkishToUpper();
        return haystack.includes(meta[3].turkishToUpper());
    };

// Case insensitive begins search
    $.expr.pseudos.ibegins = function (obj, index, meta) {
        var $obj = $(obj);
        var haystack = ($obj.data('tokens') || $obj.text()).toString().turkishToUpper();
        return haystack.startsWith(meta[3].turkishToUpper());
    };

// Case and accent insensitive contains search
    $.expr.pseudos.aicontains = function (obj, index, meta) {
        var $obj = $(obj);
        var haystack = ($obj.data('tokens') || $obj.data('normalizedText') || $obj.text()).toString().turkishToUpper();
        return haystack.includes(meta[3].turkishToUpper());
    };

// Case and accent insensitive begins search
    $.expr.pseudos.aibegins = function (obj, index, meta) {
        var $obj = $(obj);
        var haystack = ($obj.data('tokens') || $obj.data('normalizedText') || $obj.text()).toString().turkishToUpper();
        return haystack.startsWith(meta[3].turkishToUpper());
    };
})(jQuery);